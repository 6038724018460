<template>
  <div class="e_store-container min-h-screen my-10">
    <MaterialsCatogriesWithSupportFilter
      :support-filter="store?.support_filters === 1"
      :use-categories-domain="true"
      :fetch-params="{ store_id: store?.id }"
    />
    <MaterialsGrid
      class="mb-5 mt-2"
      :fetch-route="domainRoutes.materialsByServer"
      with-query-params
      :fetch-params="{ store_id: store?.id }"
    />
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import { useDomainCategoriesStore } from '~/store/domain_categories'
const { isAuthLoggedIn } = useMenuModel()
const domainRoutes = getAllRoutes(isAuthLoggedIn()).materialsRoutes.domainRoutes
const route = useRoute()
const { store } = useDomainState()
const { url } = useDomainHost()
const { t } = useI18n()

const { categoriesByCategoryStyle } = useDomainCategoriesStore()

const categoryMeta = computed(() => {
  if (route.query.category_id) {
    const category = categoriesByCategoryStyle.find(
      // @ts-ignore

      (el) => el.id == route.query.category_id
    )
    if (category) {
      return {
        title: category.name,
        image: category.image
      }
    }
  }

  return {
    title: 'المنتجات',
    image: url + '/images/e-store.webp'
  }
})

useServerSeoMeta(
  useOgMeta(
    url + '/materials',
    categoryMeta.value.title,
    store.value?.about,
    categoryMeta.value.image
  )
)

useHead({
  title: () => categoryMeta.value.title,
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/materials')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, store.value?.name, url),
      useSeoBreadcrumbItem(2, categoryMeta.value.title, url + '/materials')
    ])
  ]
})
</script>
